import { RemoveFalsyElements } from 'logic/arrayOperations';

import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import ReactMarkdown from 'react-markdown'

// OBSOLETE - Use Intl.NumberFormat instead.
// Places thousand separators where needed in a string representing
// a large number, for example, '1000000' would become '1.000.000'.
export function insertThousandsSeparator(text, separator = '.') {				// HL 6 2022 not in use
	// Source: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch06s12.html
	return text.replace(/[0-9](?=(?:[0-9]{3})+(?![0-9]))/g, `$&${separator}`);
}

export const isStringNullOrEmpty = str => !str || str.length <= 0;

// Verifies if a string contains any matching occurence of another (smaller) string,
// the test is case insensitive, a word in lowercase will match its uppercase counterpart.
export function containsText(textToSearch, textToFind) {						// HL 6 2022 not in use
	return `/${textToFind}/i`.test(textToSearch);
}

// Verifies that a text is at least formatted in a way that resembles an email address.
export function isValidEmailAddress(text) {
	return /.+@.+\..+/.test(text);
}

// Verifies that a text is at least formatted in a way that resembles phone number
export function isValidPhoneNumber(text) {
	return /^\+?[0-9]{11,12}$/.test(text);
}

// Generates a random "GUID-like" 16 characters string.
export function randomGuid() {													// HL 6 2022 not in use
	return 'xxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		// eslint-disable-next-line no-bitwise
		const r = Math.random() * 16 | 0;
		// eslint-disable-next-line no-bitwise, no-mixed-operators
		const v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

// TODO It would be so much simpler if the "id" was included in every data object coming from the server.
// Takes the id out of a uri assuming it is located after the last "/", like ".../wonen/12908329037549012".
export function getIdFromApiUrl(url) {
	if (isStringNullOrEmpty(url))
		return '';

	const splitUrl = RemoveFalsyElements(url.split('/'));

	if (splitUrl.length <= 0)
		return '';

	return splitUrl[splitUrl.length - 1];
}

export function convertToHtml(text) {
	return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

function replaceShortcode(shortcode) {
	var matches = shortcode.match(/\[(.*?):(.*?)\]/);

	// Check if it contains brackets
	if (( matches != null) && (Array.isArray(matches)) && (matches.length > 1)) {
		switch(matches[1]) {
			case 'link': 
				var linkMatches = matches[2].match(/(.*?):(.*)/);
				if ((linkMatches != null) && (Array.isArray(linkMatches)) ) {
					if (linkMatches.length == 3) {
						return `<a href src=${linkMatches[2]}>${linkMatches[1]}</a>`;
					}
				}
				break;
			default: 
				console.log('unknown shortcode');
				break;
		}
	}
	// unknow shortcode
	console.log('unknown shortcode');
	return shortcode;
}


// MarkDown
export function parseNecessaryMd(md) {
	// let mdArray = md.split(/(\[.*?\]\(.*?\))/g);
	let mdArray = md.split(/(\[.*?\]\(.*?\)|\[vid_\d{9}\])/g);			// split on links and video tags
	// console.log(md);
	// console.log(mdArray);
	for( let ii=0; ii < mdArray.length; ii++ ){
		if( mdArray[ii].match( /[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/g ) ){	// links
			let title = mdArray[ii].replace(/\[(.*)\](.*)/g, "$1");
			let link = mdArray[ii].replace(/(.*)\((.*)\)/g, "$2");

		// if ( mdArray[ii].match(/\[([^\]]+)\]\(([^\)]+)\)/g)) {
		// 	let title = mdArray[ii].replace(/\[([^\]]+)\]([^\)]+)/g, "$1");
		// 	let link = mdArray[ii].replace(/([^\]]+)\(([^\)]+)\)/g, "$2");
			if( mdArray[ii].match(/http/) ){   						 // [www.example.com](https://www.example.com)
				mdArray[ii] = ( <a href={link} target="_blank">{title}</a> );        // 
			} //
			else{													 // [complex 1](/volleypedia/I20210525222418974926)
				// mdArray[ii] = ( 
				// 	<Button
				// 		variant="contained"
				// 		style={ { justifyContent: 'flex-start', 
				// 			textAlign: 'left', 
				// 			textTransform: 'none',
				// 			background: 'transparent',
				// 			// background: theme.palette.background.paper,
				// 			// color: theme.palette.text.primary,
				// 			margin: '2px',
				// 			// padding: '10px',
				// 			textTransform: 'none',
				// 			'&:hover': {
				// 				// backgroundColor: theme.palette.background.backgroundPaper,
				// 			}, 
				// 		} } 
				// 	>
				// 	<Link to={link}>{title}</Link></Button> );    // 
				mdArray[ii] = ( <Link to={link}>{title}</Link> );    // 
			} //
		} 
		else if( mdArray[ii].match( /\[vid_\d{9}\]/gi ) ){											// video component [vid_123456789]
			// mdArray[ii] = (<span
			// 	dangerouslySetInnerHTML={{ __html: "==== VIDEO FOUND ====" }}
			// />);
			let video_id = mdArray[ii].replace(/\[vid_(\d{9})\]/gi, "$1");
			mdArray[ii] = ( <iframe
						src={encodeURI(`https://player.vimeo.com/video/${video_id}?badge=0&amp;autopause=0&amp;player_id=0`)}
						style={{
							height: '400px',
							width: '100%',
							padding: "20px 0px 10px 0px",
						}}
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowFullScreen
						title="170721_BHA_89B"
				/> );    // 
		}
		else {																						// all other tekst
			// mdArray[ii] = (<Typography
			// 				variant="inherit"
			// 				style={{ textAlign: 'justify' }}
			// 				dangerouslySetInnerHTML={{ __html: convertToHtml(mdArray[ii]) }}
			// 				/>)
			// mdArray[ii] = (<Typography
			// 				variant="inherit"
			// 				style={{ textAlign: 'justify' }}
			// 				dangerouslySetInnerHTML={{ __html: mdArray[ii] }}
			// 				/>)

			mdArray[ii] = (<span
							dangerouslySetInnerHTML={{ __html: mdArray[ii] }}
							/>)
		}


	}
	console.log(mdArray);
	return mdArray;
}

export function parseMd(md){
	console.log('mardown text', md);
	// Should we parse all text or only images, links, etc
	let parse = false;
	// if (!md.match(/\[markdown\]/)) {
	// 	// console.log(`Don't parse markdown`);
	// 	return parseNecessaryMd(md);;
	// }
	md = md.replace(/\[markdown\](\r\n?|\r?|\n?)/, '');
	// console.log('parse markdown');

	// return -> break
	// but not before headings or list items
	// prevent double breaks
	// md = md.replace(/(\n|\n\r){4}(?=[^#\d\*\-+])/gs, '<br>');
	// md = md.replace(/(\n|\n\r){3}(?=[^#\d\*\-+])/gs, '<br>');
	// md = md.replace(/(\n|\n\r){2}(?=[^#\d\*\-+])/gs, '<br>');
	// md = md.replace(/(\n|\n\r){1}(?=[^#\d\*\-+])/gs, '<br>');

	// return -> break
	md = md.replace(/(\n|\n\r)(?=[^#\d\*\-+])/gs, '<br>');

	// //ul OLD
	// md = md.replace(/^\s*\n\*/gm, '<ul>\n*');
	// md = md.replace(/^(\*.+)\s*\n([^\*])/gm, '$1\n</ul>\n\n$2');
	// md = md.replace(/^\*(.+)/gm, '<li>$1</li>');

	//ul 																		unordered list, 1 level
	md = md.replace(/^\s*[\*\-+]\s+(.*)$/gm, '<li key="$1">$1</li>');
	md = md.replace(/((<li.*?>[^\n\r]*<\/li>[\n\r])+)/gs, '<ul>\n$1</ul>');
	
	//ol OLD
	// md = md.replace(/^\s*\n\d\./gm, '<ol>\n1.');
	// md = md.replace(/^(\d\..+)\s*\n([^\d\.])/gm, '$1\n</ol>\n\n$2');
	// md = md.replace(/^\d\.(.+)/gm, '<li>$1</li>');

	// //ol NEW																    ordered list, 1 level
	md = md.replace(/^1\.\s+(.*)/gm, '<ol>\n<li key="$1">$1</li>\n');
	md = md.replace(/^\d\.\s+(.*)/gm, '<li key="$1">$1</li>\n');
	md = md.replace(/(<ol>[\n\r]+(<li.*?>[^\n\r]*<\/li>[\n\r]+)+)/gs, '$1</ol>');
	
	//blockquote
	md = md.replace(/^\>(.+)/gm, '<blockquote>$1</blockquote>');
	
	//h and remove additional linebreaks/carriage returns
	// md = md.replace(/[\#]{6}(.+)(\r\n?|\r?|\n?|<\br>)/gi, '<h6>$1</h6>'); OLD setup

	md = md.replace(/[\#]{6}\s*(.+)(\r\n|\r|\n)(<br>)?/gi, '<h6>$1</h6>');
	md = md.replace(/[\#]{5}\s*(.+)(\r\n|\r|\n)(<br>)?/gi, '<h5>$1</h5>');
	md = md.replace(/[\#]{4}\s*(.+)(\r\n|\r|\n)(<br>)?/gi, '<h4>$1</h4>');
	md = md.replace(/[\#]{3}\s*(.+)(\r\n|\r|\n)(<br>)?/gi, '<h3>$1</h3>');
	md = md.replace(/[\#]{2}\s*(.+)(\r\n|\r|\n)(<br>)?/gi, '<h2>$1</h2>');
	md = md.replace(/[\#]{1}\s*(.+)(\r\n|\r|\n)(<br>)?/gi, '<h1>$1</h1>');
/*
	md = md.replace(/[\#]{6}(.+)/g, '<h6>$1</h6>');
	md = md.replace(/[\#]{5}(.+)/g, '<h5>$1</h5>');
	md = md.replace(/[\#]{4}(.+)/g, '<h4>$1</h4>');
	md = md.replace(/[\#]{3}(.+)/g, '<h3>$1</h3>');
	md = md.replace(/[\#]{2}(.+)/g, '<h2>$1</h2>');
	md = md.replace(/[\#]{1}(.+)/g, '<h1>$1</h1>');
*/	
	//alt h
	md = md.replace(/^(.+)\n\=+/gm, '<h1>$1</h1>');
	md = md.replace(/^(.+)\n\-+/gm, '<h2>$1</h2>');
	
	// remove breaks after headers, ol, ul
	md = md.replace(/(?=<\/h\d>\s*)(<br>)/gs, '');
	md = md.replace(/(<\/ol>\s*<br>)/gs, '</ol>');
	md = md.replace(/(<\/ul>\s*<br>)/gs, '</ul>');

	//images
	md = md.replace(/\!\[([^\]]+)\]\(([^\)]+)\)/gs, 
		'<img style="max-width:100%; min-width:240px; min-height:300px; max-height:500px; " src="$2" alt="$1" />');

	//font styles
	md = md.replace(/\*\*(.+?)\*\*/gm, '<b>$1</b>');
	md = md.replace(/\_\_(.+?)\_\_/gm, '<i>$1</i>');
	md = md.replace(/\~\~(.+?)\~\~/gm, '<del>$1</del>');
	
	//pre
	md = md.replace(/^\s*\n\`\`\`(([^\s]+))?/gm, '<pre class="$2">');
	md = md.replace(/^\`\`\`\s*\n/gm, '</pre>\n\n');
	
	//code
	md = md.replace(/[\`]{1}([^\`]+)[\`]{1}/g, '<code>$1</code>');
	
	//p
	// md = md.replace(/^\s*(\n)?(.+)/gm, function(m){
	//   return  /\<(\/)?(h\d|ul|ol|li|blockquote|pre|img)/.test(m) ? m : '<p>'+m+'</p>';
	// });
	
	//strip p from pre
	md = md.replace(/(\<pre.+\>)\s*\n\<p\>(.+)\<\/p\>/gm, '$1$2');


	// links
	return md = parseNecessaryMd(md);
	
}

// export function parseBrackets(text) {  //HL 1-2024 OLD
// 	// text = text + '1234<Link to="volleypedia/I20210525222427712743">AND some other vlp article</Link>';
//  // return ( <Markdown>{text}</Markdown> );
// 	let parsedTextObject = parseMd(text);
// 	return parsedTextObject;
// }


export function createButton(text) {  
    return ( <Button>{text}</Button> );
}

