// --- Framework
import React from 'react';
import PropTypes from 'prop-types';

// --- External tools
import {
	Route,
	Switch,
	Redirect,
	withRouter,
} from 'react-router-dom';
import { compose } from 'redux';

// --- IO
import API from 'io/API';
import Logging_API from 'io/API/LoggingAPI';
// import { getUserBookmarkDict } from 'io/API/API_logic/getUserBookmarkDict';
import API_logic from 'io/API/API_logic/getUserBookmarkDict.js';
import { getErrorMessageFromResponse } from 'logic/requestOperations';

// --- Logic
import ThemeKey from 'logic/enums/ThemeKey';
import StorageKey from 'logic/enums/StorageKey';
import LoadingStatus from 'logic/enums/LoadingStatus';
import RangeOperator from 'logic/enums/RangeOperator';
import * as JsonExtension from 'logic/jsonOperations';
import TranslationKey from 'logic/enums/TranslationKey';
import { setIsSuperset, setIncludesAny } from 'logic/setOperations';
import AuthenticationStatus from 'logic/enums/AuthenticationStatus';
import { removeNullParametersFromObject } from 'logic/objectOperations';
import { isStringNullOrEmpty, getIdFromApiUrl } from 'logic/stringOperations';
import { formatUserData, getStoredSessionCredentials } from 'logic/userOperations';
import i18n, { defaultConfiguration as i18nDefaultConfiguration } from 'logic/translation/i18n';

// --- External components
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import { withTranslation } from 'react-i18next';

// --- Components
import AppTheme from 'visual/AppTheme';
import NavBar from 'visual/components/_/navigation/NavBar';
import SplashScreen from 'visual/components/_/loading/SplashScreen';
import ApplyFiltersModal from 'visual/components/modals/ApplyFiltersModal';
import ApplyFiltersDrawer from 'visual/components/Menus/ApplyFiltersDrawer';
import { visitorRoutes, authenticatedRoutes } from 'visual/components/routes';
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';

// --- Style
import './AppStyle.sass';
import ComparisonOperator from './logic/enums/ComparisonOperator';
import { mdiConsoleLine } from '@mdi/js';

const TransitionComponent = (props, ref) => <Slide direction="up" ref={ref} {...props} />;
export const Transition = React.forwardRef(TransitionComponent);

import { browserHistory } from 'react-router';


// First component (at the top of the hierarchy, aka. root component).
class App extends React.Component {
	constructor(props) {
		super(props);

		const { location } = props;

		let currentPath = '';
		if (location != null && !isStringNullOrEmpty(location.pathname)) {
			const path = location.pathname.substring(1).split('/', 1);

			if (path.length >= 1 && !isStringNullOrEmpty(path[0])) {
				// eslint-disable-next-line prefer-destructuring
				currentPath = path[0];
			}
		}

		this.state = {
			currentPath,
			filters: null,
			userData: null,
			houseDetails: {},
			drillDetails: {},
			contactData: null,
			adCategories: null,
			currentPathname: '',
			houseList: undefined,
			filtersEnabled: false,
			filteredHouseList: [],
			fullBookmarkDict: {allBookmarks: []},
			supportedThemes: null,
			supportedLevels: null,
			supportedBookmarkFolderTypes: null,
			filterPreference: null,
			booleanSelectors: null,
			bookmarkDictionary: {},
			informationTexts: null,
			learningCurves: [],
			supportedTechnics: null,
			isFilterModalOpen: false,
			supportedLanguages: null,
			supportedSubTechnics: null,
			isLoadingFilteredList: false,
			splashScreenAnimationEnded: false,
			appLoadingStatus: LoadingStatus.None,
			authenticationStatus: AuthenticationStatus.Unknown,
			theme: localStorage.getItem(StorageKey.Theme) || ThemeKey.Light,
			searchText: '',
			noInternetError: false,
		};

		this.onSignedIn = this.onSignedIn.bind(this);
		this.fetchDrills = this.fetchDrills.bind(this);
		this.onSignedOut = this.onSignedOut.bind(this);
		this.switchTheme = this.switchTheme.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.toggleFilters = this.toggleFilters.bind(this);
		this.updateFilters = this.updateFilters.bind(this);
		this.addUserBookmarkFolder = this.addUserBookmarkFolder.bind(this);
		this.fetchUserData = this.fetchUserData.bind(this);
		this.patchUserData = this.patchUserData.bind(this);
		this.patchFolderBookmarkData = this.patchFolderBookmarkData.bind(this);
		// this.toggleBookmark = this.toggleBookmark.bind(this);
		this.addBookmarkToFolder = this.addBookmarkToFolder.bind(this);
		this.removeBookmarkFromFolder = this.removeBookmarkFromFolder.bind(this);
		this.switchLanguage = this.switchLanguage.bind(this);
		this.getDrillDetails = this.getDrillDetails.bind(this);
		this.onAccountEdited = this.onAccountEdited.bind(this);
		this.openFilterModal = this.openFilterModal.bind(this);
		this.onLocationChanged = this.onLocationChanged.bind(this);
		// this.fetchFilteredHouses = this.fetchFilteredHouses.bind(this);
		this.fetchSupportedLevels = this.fetchSupportedLevels.bind(this);
		this.fetchSupportedBookmarkFolderTypes = this.fetchSupportedBookmarkFolderTypes.bind(this);
		this.onCredentialsChanged = this.onCredentialsChanged.bind(this);
		this.applyUserPreferences = this.applyUserPreferences.bind(this);
		this.fetchInformationTexts = this.fetchInformationTexts.bind(this);
		this.fetchLearningCurves = this.fetchLearningCurves.bind(this);
		this.fetchSupportedTechnics = this.fetchSupportedTechnics.bind(this);
		this.switchPushNotifications = this.switchPushNotifications.bind(this);
		this.getPreferencesForNewUser = this.getPreferencesForNewUser.bind(this);
		this.switchEmailNotifications = this.switchEmailNotifications.bind(this);
		this.fetchUserFilterPreference = this.fetchUserFilterPreference.bind(this);
		this.formatListResponseDataItem = this.formatListResponseDataItem.bind(this);
		this.deleteBookmarkFolder = this.deleteBookmarkFolder.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
		this.clearSearchText = this.clearSearchText.bind(this);
		this.addLogging = this.addLogging.bind(this);
		this.checkErrors = this.checkErrors.bind(this);

		// Refresh user data everytime the route changes
		this.props.history.listen( location =>  {
			//Do your stuff here
			if ( (location.pathname == '/bookmarks')
				|| (location.pathname == '/home') 
				|| (location.pathname == '/drills')) {
				this.fetchUserData();
			}
		   });
	}

	checkErrors(response) {
		const getErrorMessage = getErrorMessageFromResponse(response, 200);
		if (getErrorMessage != null) {
			// Check if values exists
			if (getErrorMessage.values) {
				// They exist, so reset any no-internet warings
				if (this.state.noInternetError) {
					this.setState({noInternetError: false});
				}
				// Check if password was correct
				if (getErrorMessage.values.status == 401) {
					console.log('Wrong username or password, sign the user out');
					this.onSignedOut();
					window.location.reload(false);
				}
			}
			else {
				// Probably a network error, inform the user with the given error
				this.setState({noInternetError: true});
				return true; //error
			}
		}
		else {
			// No error reset internet error if it was set
			if (this.state.noInternetError) {
				this.setState({noInternetError: false});
			}
			return; //error
		}
	}

	// --- Framework methods
	async componentDidMount() {
		this._ismounted = true;


		console.log(' ////////////////// crypto \\\\\\\\\\\\\\\\\\\\\\\\ ');
		
// var AES = require("crypto-js/aes");
var CryptoJS = require("crypto-js");
// Encrypt
var ciphertext = CryptoJS.AES.encrypt('my message', 'secret key 123').toString();

console.log('crypto ciphertext:', ciphertext);
// Decrypt
var bytes  = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
var originalText = bytes.toString(CryptoJS.enc.Utf8);

console.log('crypto originalText:', originalText);




		console.log(' --- crypto end --- ');




		console.log('+++++++++++++++++ clear all cache, always'); 
		if( 'caches' in window ){
			caches.keys().then((names) => {
			// Delete all the cache files
				names.forEach(name => {
					caches.delete(name);
					console.log('cache filename cleared(version):  ', name);
				})
			});
			// Makes sure the page reloads. Changes are only visible after you refresh.
			window.location.reload(true);
		}


		// // HL 2 2024: Cache leegmaken bij nieuwe versie (in .env file)
		// // https://stackoverflow.com/questions/51207570/how-to-clear-browser-cache-in-reactjs
		// let version = localStorage.getItem('version');
		// console.log('localStorage version:', version);
		// console.log('process.env.REACT_APP_VERSION', process.env.REACT_APP_VERSION);
		// // caching= ()=> {
		// 	// let version = localStorage.getItem('version');
		// 		if( version != process.env.REACT_APP_VERSION ){
		// 			console.log('+++++++++++++++++ current version NOT EQ cache version.'); 
		// 			if( 'caches' in window ){
		// 				caches.keys().then((names) => {
		// 				// Delete all the cache files
		// 					names.forEach(name => {
		// 						// caches.delete(name);
		// 						console.log('cache filename (version):  ', name);
		// 					})
		// 				});
		// 				// Makes sure the page reloads. Changes are only visible after you refresh.
		// 				window.location.reload(true);
		// 			}
		// 			localStorage.clear();
		// 			localStorage.setItem('version',process.env.REACT_APP_VERSION);
		// 		}
		// 	// };





		// Brings back the scrolling to the top of the page when the route changes.
		this.stopListeningToHistoryChanges = this.props.history.listen(this.onLocationChanged);

		const { appLoadingStatus } = this.state;

		if (appLoadingStatus !== LoadingStatus.None)
			return;

		await this.setState({ appLoadingStatus: LoadingStatus.Started });

		if (!this._ismounted)
			return;

		// Requests that must resolve before displaying the app.
		await Promise.all([
			this.fetchBooleanSelectors(),
			this.fetchLanguages(),
			this.fetchThemes(),
			this.fetchSupportedLevels(),
			// this.fetchSupportedTechnics(),    //HL 4 2022 changing order to load stuff
			// this.fetchLearningCurves(),
			this.fetchSupportedBookmarkFolderTypes(),  //HL 5 2022: deze moet wel hier, new folder faalt anders

//			this.fetchUserData(),
		]);

		if (!this._ismounted)
			return;
			
		await this.fetchUserData();

		// Requests that must resolve before displaying the app,
		// but after the initialization requests have resolved.

		if (!this._ismounted)
			return;

		if (this.state.authenticationStatus !== AuthenticationStatus.Authenticated) {
			await this.setState({ appLoadingStatus: LoadingStatus.Completed, splashScreenAnimationEnded: true });

			// Requests that can resolve after displaying the app.
			this.fetchInformationTexts();

//			this.fetchUserData();
			return;
		}

		this.fetchSupportedTechnics();  //HL 4 2022  changing order to load stuff
		this.fetchLearningCurves();
		// this.fetchSupportedBookmarkFolderTypes();


		// await this.fetchHouses();
		await this.fetchDrills();

		this.addLogging(this.state.userData);

		if (!this._ismounted)
			return;

		if (!this._ismounted)
			return;

		setTimeout(() => {
			if (!this._ismounted)
				return;

			this.setState({ splashScreenAnimationEnded: true });
		}, 1000);

		this.setState({ appLoadingStatus: LoadingStatus.Completed });

		// Requests that can resolve after displaying the app.
		this.fetchInformationTexts();
		this.fetchLearningCurves();
//		this.fetchLog();
		this.setfirstLogin();
	}

	componentWillUnmount() {
		this._ismounted = false;
		this.stopListeningToHistoryChanges();
	}

	// Gets called everytime a prop changes, allowing to update
	// the component's state depending on old and new prop values.
	static getDerivedStateFromProps(nextProps, previousState) {
		const { location } = nextProps;
		const { currentPathname } = previousState;

		const nextState = {};

		// Determines if the pathname has changed, play transition then.
		if (location.pathname !== currentPathname)
			nextState.currentPathname = location.pathname;

		return nextState;
	}

	onSearchChange(searchText) {
		 
		this.setState({searchText: searchText});
	}


	setfirstLogin() {
		const { authenticationStatus } = this.state;

		if (authenticationStatus !== AuthenticationStatus.Authenticated)
			return;

		if ( (this.state.userData.first_login != null)
			&& (this.state.userData.first_login != '' ) ) {
			console.log('***** first login was already set *****');
			return;
		}
		console.log('***** Set first login *****');
		// console.log(new Date().getTime());
		// this.patchUserData({ first_login: new Date().getTime() });
		console.log(new Date().toISOString());
		this.patchUserData({ first_login: new Date().toISOString() });
	}

	// async fetchLog() {
	// 	if (!this._ismounted || this.state.informationTexts != null)
	// 		return;

	// 	const logData = await Logging_API.getLog();

	// 	if (!this._ismounted)
	// 		return;

	// 	const log = logData.data.reduce((result, data) => {
	// 		const id = getIdFromApiUrl(data.url);

	// 		// NOTE
	// 		// Uses the label as key, not an id like most other data dictionaries.
	// 		result[data.label] = Object.freeze({ id, ...data });

	// 		return result;
	// 	}, {});

	// 	console.log('*********** log: ');
	// 	console.log(log);
	// 	console.log('/////////// log');

	// //	await this.setState({ informationTexts: Object.freeze(informationTexts) });
	// }

 
	render() {
		const {
			props: { t, location },
			state: {
				theme,
				filters,
				userData,
				houseList,
				contactData,
				adCategories,
				houseDetails,
				drillDetails,
				filtersEnabled,
				supportedLevels,
				supportedBookmarkFolderTypes,
				supportedThemes,
				filterPreference,
				booleanSelectors,
				appLoadingStatus,
				informationTexts,
				learningCurves,
				supportedTechnics,
				isFilterModalOpen,
				filteredHouseList,
				supportedLanguages,
				bookmarkDictionary,
				fullBookmarkDict,
				supportedSubTechnics,
				authenticationStatus,
				isLoadingFilteredList,
				splashScreenAnimationEnded,
				searchText,
			},
		} = this;

		let warning = null;
		let splashScreen = null;
		let content = null;
		let navigation = null;
//		console.log(`warningText: ${warningText}`);
			
		if (this.state.noInternetError) {
			let warningTitle = '';
			let warningText = '';
			// If there is no internetconnection and the user's cache is empty set default strings
			if (t(TranslationKey.title_error_server_not_reachable) == 'title_error_server_not_reachable' ) {
				warningTitle = 'Waarschuwing - Controleer de internetverbinding';
				warningText = 'De server is niet bereikbaar, controleer je internetverbinding of probeer het later nog eens.'
			}
			else {
				warningTitle = t(TranslationKey.title_error_server_not_reachable);
				warningText = t(TranslationKey.error_server_not_reachable);
			}
//			console.log(`_set warning text: ${TranslationKey.error_server_not_reachable[i18n.language]}`);
			// TODO: Use translation
//			warning = (<h1>{TranslationKey.error_server_not_reachable}</h1>);
			warning = (
				<Grid container justify = "center">
					<Grid item> 
					<Alert severity="warning">
						<AlertTitle>{warningTitle}</AlertTitle>
						{warningText}
					</Alert>
					</Grid>
				</Grid>
			);
		}
		if (!splashScreenAnimationEnded || appLoadingStatus !== LoadingStatus.Completed)
			splashScreen = <SplashScreen key="splash-screen" readyToDisplayApp={appLoadingStatus === LoadingStatus.Completed} />;

		if (appLoadingStatus === LoadingStatus.Completed) {
			if (authenticationStatus === AuthenticationStatus.Authenticated && houseList != null) {
				navigation = <NavBar routes={authenticatedRoutes}/>;

				content = (
					<Container disableGutters>
						

						<Container className="appContainer">
							<Switch>
								{authenticatedRoutes.map(route => (
									<Route
										key={route.key}
										path={route.path}
										exact={route.exact}
										// component={route.Component}
										render={props => (
											<route.Component
												{...props}
												theme={theme}
												filters={filters}
												userData={userData}
												houseList={houseList}
												contactData={contactData}
												adCategories={adCategories}
												houseDetails={houseDetails}
												drillDetails={drillDetails}
												onSignedOut={this.onSignedOut}
												switchTheme={this.switchTheme}
												filtersEnabled={filtersEnabled}
												clearFilters={this.clearFilters}
												clearSearchText={this.clearSearchText}
												supportedLevels={supportedLevels}
												supportedThemes={supportedThemes}
												updateFilters={this.updateFilters}
												toggleFilters={this.toggleFilters}
												patchUserData={this.patchUserData}
												patchFolderBookmarkData={this.patchFolderBookmarkData}
												informationTexts={informationTexts}
												booleanSelectors={booleanSelectors}
												filterPreference={filterPreference}
												switchLanguage={this.switchLanguage}
												// toggleBookmark={this.toggleBookmark}
												addBookmarkToFolder={this.addBookmarkToFolder}
												removeBookmarkFromFolder={this.removeBookmarkFromFolder}
												supportedTechnics={supportedTechnics}
												filteredHouseList={filteredHouseList}
												openFilterModal={this.openFilterModal}
												onAccountEdited={this.onAccountEdited}
												getDrillDetails={this.getDrillDetails}
												fullBookmarkDict={fullBookmarkDict}
												bookmarkDictionary={bookmarkDictionary}
												supportedLanguages={supportedLanguages}
												supportedSubTechnics={supportedSubTechnics}
												authenticationStatus={authenticationStatus}
												isLoadingFilteredList={isLoadingFilteredList}
												onCredentialsChanged={this.onCredentialsChanged}
												switchPushNotifications={this.switchPushNotifications}
												switchEmailNotifications={this.switchEmailNotifications}
												route={route}
												learningCurves={learningCurves}
												onApply={this.updateFilters}
												addUserBookmarkFolder={this.addUserBookmarkFolder}	
												deleteBookmarkFolder={this.deleteBookmarkFolder}
												searchText={searchText}
												onSearchChange={this.onSearchChange}
												checkErrors={this.checkErrors}
											/>
										)}
									/>
								))}


		

								<Redirect
									key="auto-redirect"
									to={{
										pathname: '/home',
										state: { from: location },
									}}
								/>
							</Switch>
						</Container>
					</Container>
				);
			} else {
				content = (
					<Container className="visitorRouteContainer">
						<Switch>
							{visitorRoutes.map(route => (
								<Route
									key={route.key}
									path={route.path}
									exact={route.exact}
									render={props => (
										<route.Component
											{...props}
											onSignedIn={this.onSignedIn}
											switchLanguage={this.switchLanguage}
											supportedLanguages={supportedLanguages}
											getPreferencesForNewUser={this.getPreferencesForNewUser}
											route={route}
										/>
									)}
								/>
							))}
							<Redirect
								key="auto-redirect"
								to={{
									pathname: visitorRoutes[0].href,
									state: { from: location },
								}}
							/>
						</Switch>
					</Container>
				);
			}
		}

		return (
			<AppTheme theme={theme}>
				<main>
					{warning}
					{splashScreen}
					{content}
					{navigation}
				</main>
			</AppTheme>
		);
	}


	// --- Working methods
	// TODO ERROR MANAGEMENT OF EVERY METHOD MAKING USE OF THE API.
	async fetchLanguages() {
		if (!this._ismounted || this.state.supportedLanguages != null)
			return;

		const getSupportedLanguagesResponse = await API.getSupportedLanguages();
		if (this.checkErrors(getSupportedLanguagesResponse)) {
			return;
		}

		const promises = [];
		const supportedLanguages = [];
		let resources = getSupportedLanguagesResponse.data.reduce((result, { label, url }) => {
			

// console.log('active label url');
// console.log(active);
// console.log(active[0].label);
// console.log(label);
// console.log(url);


			const splitUrl = url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			supportedLanguages.push({ id, label });

			result[label] = { id, translation: {} };


// console.log('getSupportedLanguages ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++');
// console.log(result);
			// if( active[0].label == 'ja '){
			// 	console.log('active  = ja');
			// }
			// else{
			// 	console.log('active  = nee');
			// }

			promises.push(API.get(url));

			return result;
		}, {});


// console.log('getSupportedLanguages ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++');
// console.log(resources);

		 

		await this.setState({ supportedLanguages: Object.freeze(supportedLanguages) });

		const getTranslationResponses = await Promise.all(promises);

		resources = Object.freeze(getTranslationResponses.reduce((result, { data }) => {
			const { languages: label } = data;

			// Takes out the database collection name parameter,
			// to not mistakenly use it as a translation key.
			delete data.languages;

			// Removes the keys with empty (null or undefined) values
			// so that i18n uses the fallback language for those keys.
			result[label].translation = Object.freeze({ ...removeNullParametersFromObject(data) });

			return result;
		}, resources));

		 

		await i18n.init({
			...i18nDefaultConfiguration,
			resources,
			lng: localStorage.getItem(StorageKey.PreferredLanguage) || 'nl',
		});
	}

	async fetchBooleanSelectors() {
		if (!this._ismounted || this.state.supportedThemes != null)
			return;

		// The theme is currently saved using a boolean table "yes_no",
		// "ja" or "yes" meaning dark mode, "nee" or "no" meaning light mode.
		const getBooleanSelectorsResponse = await API.getBooleanSelectors();

		// This is one of the first api calls, so check if credentials are correct.
		// This shoudl be done for every call, but for now just use this as a quick
		// fix for when someone changes their password on one machine, but still has
		// their old credentials stored on the machine they're using
		if (this.checkErrors(getBooleanSelectorsResponse)) {
			return;
		}

		/*		const getErrorMessage = getErrorMessageFromResponse(getBooleanSelectorsResponse, 200);
		console.log(getErrorMessage);
		if (getErrorMessage != null) {
			// Check if password was correct
			if (getErrorMessage.values.status == 401) {
				console.log('Wrong username or password, sign the user out');
				this.onSignedOut();
				window.location.reload(false);
			}
		}
		*/

		const promises = [];
		let booleanSelectors = getBooleanSelectorsResponse.data.reduce((result, { label, url }) => {
			const splitUrl = url.split('/');
			const id = splitUrl[splitUrl.length - 1];

			result[label] = { id, label };

			promises.push(API.get(url));

			return result;
		}, {});

		const getSelectorTranslationsResponses = await Promise.all(promises);

		booleanSelectors = Object.freeze(getSelectorTranslationsResponses.reduce((result, { data }) => {
			const { yes_no: label } = data;

			delete data.yes_no;

			result[label] = Object.freeze({ ...result[label], ...data });

			return result;
		}, booleanSelectors));


// console.log('booleanSelectors -------------------------------');
// console.log(booleanSelectors);
		 

		await this.setState({ booleanSelectors });
	}

	async fetchThemes() {
		if (!this._ismounted || this.state.supportedThemes != null)
			return;

		// The theme is currently saved using a boolean table "yes_no",
		// "ja" or "yes" meaning dark mode, "nee" or "no" meaning light mode.
		const getSupportedThemesResponse = await API.getSupportedThemes();
		if (this.checkErrors(getSupportedThemesResponse)) {
			return;
		}
		const supportedThemes = getSupportedThemesResponse.data.reduce((result, { label, url }) => {
			const id = getIdFromApiUrl(url);

			if (label === 'ja')
				result.push({ id, label: ThemeKey.Dark });
			else
				result.push({ id, label: ThemeKey.Light });

			return result;
		}, []);

		 

		await this.setState({ supportedThemes: Object.freeze(supportedThemes) });
	}

	// async fetchSupportedCities() {
	// 	if (!this._ismounted || this.state.supportedCities != null)
	// 		return;
	//
	// 	const getSupportedCitiesResponse = await API.getSupportedCities();
	//
	// 	const supportedCities = getSupportedCitiesResponse.data.reduce((result, { label, url }) => {
	// 		const splitUrl = url.split('/');
	// 		const id = splitUrl[splitUrl.length - 1];
	//
	// 		result[id] = { id, label };
	//
	// 		return result;
	// 	}, {});
	//
	// 	 
	//
	// 	await this.setState({ supportedCities: Object.freeze(supportedCities) });
	// }


	async fetchSupportedLevels() {
		if (!this._ismounted || this.state.supportedLevels != null)
			return;

		const getSupportedLevelsResponse = await API.getSupportedLevels();
		if (this.checkErrors(getSupportedLevelsResponse)) {
			return;
		}

		const supportedLevels = getSupportedLevelsResponse.data.reduce((result, data) => {
			const id = getIdFromApiUrl(data.url);
			// delete data.niveaus;
			// delete data.url;

			result[id] = Object.freeze({ id, ...data });

			return result;
		}, {});

		 

		await this.setState({ supportedLevels: Object.freeze(supportedLevels) });
	}

	async fetchSupportedBookmarkFolderTypes() {
		if (!this._ismounted || this.state.supportedLevels != null)
			return;

		const getSupportedBookmarkFolderTypesResponse = await API.getBookmarkFolderTypes();
		
		console.log(getSupportedBookmarkFolderTypesResponse); //HL 5 2022

		if ( this.checkErrors(getSupportedBookmarkFolderTypesResponse) ) { return };
		const supportedBookmarkFolderTypes = getSupportedBookmarkFolderTypesResponse.data.reduce((result, data) => {
			const id = getIdFromApiUrl(data.url);
			// delete data.niveaus;
			// delete data.url;

			result[id] = Object.freeze({ id, ...data });

			return result;
		}, {});

		 

		await this.setState({ supportedBookmarkFolderTypes: Object.freeze(supportedBookmarkFolderTypes) });
	}

	async fetchSupportedTechnics() {
		// if (!this._ismounted || this.state.supportedTechnics != null)
		// 	return;
// HL 4 2022  weggehaald, omdat het bij aanpassen van taal in de weg zit.

		const getSupportedTechnicsResponse = await API.getSupportedTechnics();

		// if (this.checkErrors(getSupportedTechnicsResponse)) {
		// 	return;
		// }
// HL 4 2022  weggehaald, omdat het bij aanpassen van taal in de weg zit.
// lijkt niet zo belangrijk  ??

		const supportedSubTechnics = {};
		const supportedTechnics = getSupportedTechnicsResponse.data.reduce((result, data) => {
			const { url, subtechniek } = data;

			// Gets the id out of the url parameter.
			const id = getIdFromApiUrl(url);

			// If a subtechniek parameter (array of objects) exists and and isn't empty,
			// gets the id of each sub technic to be added to the technic object, so that
			// a fast lookup in the sub technic dictionary can be done from the technic.
			let subTechnicIds = [];
			if (subtechniek != null && subtechniek.length > 0) {
				subTechnicIds = subtechniek.map((subTechnic) => {
					const { url: subTechnicUrl, content } = subTechnic;

					const subTechnicId = getIdFromApiUrl(subTechnicUrl);

					// Spreads the content parameter in a new object and deletes
					// the content parameter from the formatted sub technic.
					// Also adds the "parent" technic id to the sub technic.
					const formattedSubTechnic = {
						id: subTechnicId,
						technicId: id,
						...content,
						...subTechnic
					};


					if( isStringNullOrEmpty(i18n.language) ){ 
						formattedSubTechnic.i18n_label = formattedSubTechnic.label + " -- No language found --";
					}  // 
					else if( i18n.language == 'nl' ){
						formattedSubTechnic.i18n_label = formattedSubTechnic.label;
					}
					else{
						formattedSubTechnic.i18n_label = formattedSubTechnic[i18n.language];
					}

					delete formattedSubTechnic.content;
					delete formattedSubTechnic.url;

					supportedSubTechnics[subTechnicId] = Object.freeze(formattedSubTechnic);
					return subTechnicId;
				});
			}

			delete data.subtechniek;
			delete data.url;


			let i18n_label;

			if( isStringNullOrEmpty(i18n.language) ){ 
				data.i18n_label = data.label + " -- No language found --";
			}  // 
			else if( i18n.language == 'nl' ){
				data.i18n_label = data.label;
			}
			else{
				data.i18n_label = data[i18n.language];
			}


			result[id] = Object.freeze({ id, subTechnicIds, ...data });

			return result;
		}, {});





// console.log('supportedTechnics *************************');
// console.log(supportedTechnics);


// let sorted = {};		 

// Object
//     .keys(supportedTechnics).sort(function(a, b){
//         // return supportedTechnics[b].i18n_label - supportedTechnics[a].i18n_label;
//         return supportedTechnics[a].i18n_label > supportedTechnics[b].i18n_label ? 1  : 
//         	 ( supportedTechnics[a].i18n_label < supportedTechnics[b].i18n_label ? -1 : 0 );
//     })
//     .forEach(function(key) {
//         sorted[key] = supportedTechnics[key];
//     });

// console.log('supportedTechnics sorted *************************');
// console.log(sorted);

		 

		await this.setState({
			supportedTechnics: Object.freeze(supportedTechnics),
			supportedSubTechnics: Object.freeze(supportedSubTechnics)
		});
	}

	// async fetchContactData() {
	// 	if (!this._ismounted || this.state.contactData != null)
	// 		return;
	//
	// 	const getContactDataResponse = await API.getContactData();
	//
	// 	const promises = [];
	// 	let contactData = getContactDataResponse.data.reduce((result, { label, url }) => {
	// 		const splitUrl = url.split('/');
	// 		const id = splitUrl[splitUrl.length - 1];
	//
	// 		result[label] = { id, href: null };
	//
	// 		promises.push(API.get(url));
	//
	// 		return result;
	// 	}, {});
	//
	// 	const getContactDetailsResponses = await Promise.all(promises);
	//
	// 	contactData = Object.freeze(getContactDetailsResponses.reduce((result, { data }) => {
	// 		const { contact_data: label, nl } = data;
	//
	// 		result[label].href = nl;
	//
	// 		return result;
	// 	}, contactData));
	//
	// 	 
	//
	// 	await this.setState({ contactData });
	// }

	// async fetchAdCategories() {
	// 	if (!this._ismounted || this.state.adCategories != null)
	// 		return;
	//
	// 	const getAdCategoriesResponse = await API.getAdCategories();
	//
	// 	if (!this._ismounted)
	// 		return;
	//
	// 	const promises = [];
	// 	let adCategories = getAdCategoriesResponse.data.reduce((result, { label, url }) => {
	// 		const splitUrl = url.split('/');
	// 		const id = splitUrl[splitUrl.length - 1];
	//
	// 		result[label] = { id };
	//
	// 		promises.push(API.get(url));
	//
	// 		return result;
	// 	}, {});
	//
	// 	const getAdCategoryResponses = await Promise.all(promises);
	//
	// 	if (!this._ismounted)
	// 		return;
	//
	// 	adCategories = Object.freeze(getAdCategoryResponses.reduce((result, { data }) => {
	// 		const { ad_categories: label } = data;
	//
	// 		delete data.ad_categories;
	//
	// 		result[label] = { ...result[label], ...data };
	//
	// 		return result;
	// 	}, adCategories));
	//
	// 	 
	//
	// 	await this.setState({ adCategories });
	// }

	async fetchInformationTexts() {
		if (!this._ismounted || this.state.informationTexts != null)
			return;

		const getInformationTextsResponse = await API.getInformationTexts();
		if ( this.checkErrors(getInformationTextsResponse) ) { return };
		if (!this._ismounted)
			return;

		const informationTexts = getInformationTextsResponse.data.reduce((result, data) => {
			const id = getIdFromApiUrl(data.url);

			// NOTE
			// Uses the label as key, not an id like most other data dictionaries.
			result[data.label] = Object.freeze({ id, ...data });

			return result;
		}, {});

		 

		await this.setState({ informationTexts: Object.freeze(informationTexts) });
	}

	async fetchLearningCurves() {
// console.log('fetchLearningCurves start *************************');
		// if (!this._ismounted || this.state.informationTexts != null)
		// 	return;
// HL 4 2022  weggehaald, omdat het bij aanpassen van taal in de weg zit.



		const getLearningCurvesResponse = await API.getLearningCurves();
		if ( this.checkErrors(getLearningCurvesResponse) ) { return };

		// if (!this._ismounted)
		// 	return;
// HL 4 2022  weggehaald, omdat het bij aanpassen van taal in de weg zit.

		const learningCurves = getLearningCurvesResponse.data.reduce((result, data) => {
			const id = getIdFromApiUrl(data.url);


			let i18n_label;

			if( isStringNullOrEmpty(i18n.language) ){ 
				data.i18n_label = data.label + " -- No language found --";
			}  // 
			else if( i18n.language == 'nl' ){
				data.i18n_label = data.label;
			}
			else{
				data.i18n_label = data[i18n.language];
			}





			// NOTE
			// Uses the label as key, not an id like most other data dictionaries.
			result[data.label] = Object.freeze({ id, ...data });

			return result;
		}, {});


// console.log('learningCurves end *************************');
// console.log(learningCurves);
		 

		// await this.setState({ learningCurves: Object.freeze(learningCurves) });
		await this.setState({learningCurves});
// HL Kun je een freeze er zomaar uithalen? Neen, lijkt er niet op

	}

	async fetchUserData() {

		if (!this._ismounted)
			return;
			 

		const json = getStoredSessionCredentials();

		if (json == null) {
			await this.setState({ authenticationStatus: AuthenticationStatus.Visitor });
			return;
		}

		const { username, password } = json;

		const response = await API.getUserFromCredentials(username, password);
		// console.log(`response: ${response}`);
		if ( this.checkErrors(response) ) { return };

		if (!this._ismounted)
			return;

		if (response.data == null || response.data.length <= 0) {
			// Something went wrong, maybe the user has been deleted or his
			// credentials changed, the stored credentials should be discarded.

			console.warn('Could not retrieve user data from stored credentials, continuing as visitor.');

			localStorage.removeItem(StorageKey.UserCredentials);
			await this.setState({ authenticationStatus: AuthenticationStatus.Visitor });
			return;
		}

		const userData = formatUserData(response.data[0]);			

		await this.setState({
			userData,
			authenticationStatus: AuthenticationStatus.Authenticated
		});

//		this.addLogging(userData);


		this.applyUserPreferences(userData);
		const bookmarkDict = await API_logic.getUserBookmarkDict(userData.id, userData.bookmarkFolderDict, this.state.supportedBookmarkFolderTypes);
		// TODO: state does not store 'allBookmarks' array
		await this.setState({ fullBookmarkDict: Object.freeze(bookmarkDict) });
//		await this.setState({ fullBookmarkDict: {...bookmarkDict} });

		// await this.fetchUserFilterPreference();
	}

	async deleteBookmarkFolder(folderId) {
		 
		// Check if it is allowed to remove
		if (this.state.fullBookmarkDict[folderId].type != 'private') {
			 
		}
		else {
			API.deleteFolder(folderId);
			this.fetchUserData();
		}
	}

	async fetchUserFilterPreference() {
		if (!this._ismounted || this.state.filterPreference != null)
			return;

		if (this.state.authenticationStatus !== AuthenticationStatus.Authenticated)
			return;

		const { userData } = this.state;

		const response = await API.getFilterPreferenceByUserID(userData.id);
		if ( this.checkErrors(response) ) { return };


		if (!this._ismounted || response.status !== 200 || response.data == null) {
			console.warn('Cannot update state with user filter preference.');
			return;
		}

		if (response.data.length <= 0) {
			 
			return;
		}

		const {
			url,
			plaats,
			soort_object,
			koopprijs_min,
			koopprijs_max,
			aantal_slaapkamers_min,
		} = response.data[0];

		const filterPreference = {
			id: getIdFromApiUrl(url),
			koopprijs_min: koopprijs_min == null ? null : Number.parseInt(koopprijs_min, 10),
			koopprijs_max: koopprijs_max == null ? null : Number.parseInt(koopprijs_max, 10),
			aantal_slaapkamers_min: aantal_slaapkamers_min == null ? null : Number.parseInt(aantal_slaapkamers_min, 10),
			plaats: plaats == null ? null : plaats.map(item => getIdFromApiUrl(item.url)),
			soort_object: soort_object == null ? null : soort_object.map(item => getIdFromApiUrl(item.url)),
		};

		await this.setState({ filterPreference });
		await this.updateFilters(filterPreference);
	}

	// NOTE
	// The following was copied from the getDrillDetails method (which is not used in this configuration)
	// but I want to point out that this is very difficult to maintain efficiently and adds a significant
	// computational overhead as all those operations are done for each and every element in the list (1000+).
	// An exception to this statement is the technic and sub-technic Sets that do have an overhead in this
	// method, but then save some computation complexity when filtering the drills locally in the front-end.
	formatListResponseDataItem(item) {
		const id = getIdFromApiUrl(item.url);

		// TODO It's better to fix typing errors like this at the source as early as possible instead of duct tapping.
		// CHECK Seeing "item.titel[0]", Why would there ever be multiple titles and is it a desirable scenario?
		// TODO "titel" or "title" should be renamed into "nl" (and "en" should be added alongside it)
		//  so that the drills title text can be displayed in the language selected by the user.
		if (Array.isArray(item.titel))
			item.title = item.titel.length > 0 ? item.titel[0] : TranslationKey.unknown;
		else
			item.title = item.titel;

		delete item.titel;

		// TODO No real text should be used in data objects, translations are here for that, something
		//  like "TranslationKey.none" or "TranslationKey.no_technic" should be used instead of "Geen",
		//  just like it was nicely done above with "TranslationKey.unknown".
		let mainTechnique = 'Geen';
		let mainTechniqueId = '';
		const technicSet = new Set();

		if (item.hoofdtechniek != null && item.hoofdtechniek.length > 0) {
			// TODO This isn't a viable option, only the id of the technic can allow to retrieve
			//  the technic in the supportedTechnics dictionary, and find the "nl" and "en" strings.
			mainTechnique = item.hoofdtechniek[0].label;

			mainTechniqueId = getIdFromApiUrl(item.hoofdtechniek[0].url);

			// Adds the id of each technics of this item, a Set cannot contain duplicates
			// and it is made to perform fast when checking if they contain a value (or a set
			// of values), which makes it useful to filter items matching one to many technics.
			for (let i = 0; i < item.hoofdtechniek.length; i++) {
				const { url } = item.hoofdtechniek[i];
				const technicId = getIdFromApiUrl(url);

				technicSet.add(technicId);
			}
		}

		item.mainTechnique = mainTechnique;
		item.mainTechniqueId = mainTechniqueId;
		item.technicSet = technicSet;

		const subTechnicSet = new Set();
		if (item.subtechniek != null && item.subtechniek.length > 0) {
			for (let i = 0; i < item.subtechniek.length; i++)
				subTechnicSet.add(getIdFromApiUrl(item.subtechniek[i].url));
		}

		item.subTechnicSet = subTechnicSet;

		let smallestPlayerCount = 0;

		for (let i = 0; i < item.minimum_aantal.length; i++) {
			const playerCount = parseInt(item.minimum_aantal[i].label, 10);

			if (smallestPlayerCount <= 0 || playerCount < smallestPlayerCount)
				smallestPlayerCount = playerCount;
		}

		item.minPlayers = smallestPlayerCount <= 0 ? 1 : smallestPlayerCount;

		// Let's do a trick. Each level will have a unique numeric value. Each combination of levels
		// will result in an addition of their unique values, which will result in a unique sum (levelCode). 
		// This will quickly identify the set of levels that is active, so an icon can be shown for example
		// item 1=5
		// item 2=9
		// item 3=12
		let level = '';
		const levelSet = new Set();
		let levelCode = 0;
		if (item.niveau != null && item.niveau.length > 0) {
			level = item.niveau[0].label;
//			 

			// Adds the id of each levels of this item, a Set cannot contain duplicates
			// and it is made to perform fast when checking if they contain a value (or a set
			// of values), which makes it useful to filter items matching one to many levels.
			for (let i = 0; i < item.niveau.length; i++) {
				let levelID = getIdFromApiUrl(item.niveau[i].url);
				levelSet.add(levelID);
//				 
				switch (this.state.supportedLevels[levelID].value) {
					case '1': levelCode += 5;
							break;
					case '2': levelCode += 9;
							break;
					case '3': levelCode += 12;
							break;
				}
			}
		}
		item.levelCode = levelCode;
		item.level = level;
		item.levelSet = levelSet;


		if( isStringNullOrEmpty(i18n.language) ){ 
			item.i18n_label = item.label + " -- NLF --";
			item.i18n_description = item.beschrijving + " -- NLF --";
		}  // 
		else if( i18n.language == 'nl' ){
			item.i18n_label = item.label;
			item.i18n_description = item.beschrijving;
		}
		else{
			item.i18n_label = item[i18n.language];
			item.i18n_description = item['beschrijving_' + i18n.language];
		}

		// item.thumbnail = thumbnail;

		return {
			id,
			...item,
		};
	}

	async fetchDrills() {
		if (!this._ismounted)
			return;

		const response = await API.getDrills();
		if ( this.checkErrors(response) ) { return };

		if (!this._ismounted)
			return;

		const houseList = response.data.reduce((result, currentItem) => {
		// const DrillsList = response.data.reduce((result, currentItem) => {   # HL 4 2022; dit werkt nog niet zo, 
			result.push(this.formatListResponseDataItem(currentItem));
			return result;
		}, []);

	// console.log('Drills ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');	 
	// console.log(houseList);

		this.setState({ houseList });
		// this.setState({ DrillsList });  # HL 4 2022; dit werkt nog niet zo, houseList moet waarschijnlijk ook op andere plaatsen aangepast
	}

	// async fetchHouses() {
	// 	if (!this._ismounted)
	// 		return;
	//
	// 	const response = await API.getHouses();
	//
	// 	if (!this._ismounted)
	// 		return;
	//
	// 	const houseList = response.data.reduce((result, currentItem) => {
	// 		const splitUrl = currentItem.url.split('/');
	// 		const id = splitUrl[splitUrl.length - 1];
	//
	// 		result.push({
	// 			id,
	// 			...currentItem,
	// 		});
	//
	// 		return result;
	// 	}, []);
	//
	// 	this.setState({ houseList });
	// }

	async getDrillDetails(id) {
		if (!this._ismounted)
			return;

		// Sets the loading house data to an empty object.
		this.setState(({ drillDetails }) => {
			drillDetails[id] = {};
			drillDetails[id].complete = true;
			return { drillDetails };
		});

		const response = await API.getDrill(id);
		if ( this.checkErrors(response) ) { return };

		if (!this._ismounted)
			return;
		//  
		// this.setState(({ drillDetails }) => {
		// 	drillDetails[id] = response.data;
		// 	drillDetails[id].title = Array.isArray(response.data.titel)? (response.data.titel.length > 0 ? response.data.titel[0] : TranslationKey.unknown) : response.data.titel;
		// 	drillDetails[id].mainTechnique = response.data.hoofdtechniek.length > 0? response.data.hoofdtechniek[0].label:"Geen";
		// 	drillDetails[id].complete = false;
		//
		// 	var smallest= 100;
		//
		// 	for (var ii=0; ii < response.data.minimum_aantal.length; ii++){
		// 			if (parseInt(response.data.minimum_aantal[ii].label, 10) < smallest) {
		//     			var smallest=parseInt(response.data.minimum_aantal[ii].label, 10);
		// 		}
		// 	}

		this.setState(({ drillDetails }) => {
			drillDetails[id] = this.formatListResponseDataItem(drillDetails[id]);
			return { drillDetails };
		});
	}

	async patchUserData(changes) {
		const {
			userData,
			authenticationStatus,
		} = this.state;
		if (authenticationStatus !== AuthenticationStatus.Authenticated) {
			console.warn('Cannot update user data while not signed in.');
			return;
		}
		const response = await API.patchUser(userData.id, changes);
		if ( this.checkErrors(response) ) { return };

		if (!this._ismounted)
			return;

		if (response.data.success == null || response.data.success !== 1) {
			console.error('Failed to patch user.');
			return;
		}
		this.fetchUserData();
	}

	async patchFolderBookmarkData(folderID, changes) {
		const {
			authenticationStatus,
		} = this.state;

		if (authenticationStatus !== AuthenticationStatus.Authenticated) {
			console.warn('Cannot update user data while not signed in.');
			return;
		}
		const response = await API.patchFolderBookmarks(folderID, changes);
		if ( this.checkErrors(response) ) { return };

		if (!this._ismounted)
			return;

		if (response.data.success == null || response.data.success !== 1) {
			console.error('Failed to patch bookmark folder data.');
			return;
		}
		this.fetchUserData();
	}


	// toggleBookmark(houseID) {
	// 	const {
	// 		bookmarkDictionary,
	// 		authenticationStatus,
	// 	} = this.state;

		 
	// 	const nextBookmarkDictionary = { ...bookmarkDictionary };
	// 	if (nextBookmarkDictionary[houseID] == null) {
			 
	// 		nextBookmarkDictionary[houseID] = houseID;
	// 	} else {
	// 		delete nextBookmarkDictionary[houseID];
			 
	// 	}

	// 	if (authenticationStatus !== AuthenticationStatus.Authenticated) {
			 
	// 		this.setState({ bookmarkDictionary: nextBookmarkDictionary });
	// 		return;
	// 	}

		 
		 
	// 	this.patchUserData({ bookmarks: Object.keys(nextBookmarkDictionary) });
	// }

	addBookmarkToFolder(folderID, drillID) {
		const {
			fullBookmarkDict,
			authenticationStatus,
		} = this.state;
		const nextFolderBookmarkDictionary = { ...fullBookmarkDict[folderID] };

		// Check if already exists
		if (fullBookmarkDict[folderID].bookmarks[drillID] == null) {
			nextFolderBookmarkDictionary.bookmarks[drillID] = drillID;
		}
		if (authenticationStatus !== AuthenticationStatus.Authenticated) {
//			this.setState({ bookmarkDictionary: nextBookmarkDictionary });
			return;
		}

//		patchFolderBookmarks({bookmarks: Object.values(nextBookmarkFolderDictionary)})
		this.patchFolderBookmarkData(folderID, { bookmarks: Object.values(nextFolderBookmarkDictionary.bookmarks) });
	}

	removeBookmarkFromFolder(folderID, drillID) {
		const {
			fullBookmarkDict,
			authenticationStatus,
		} = this.state;
		const nextFolderBookmarkDictionary = { ...fullBookmarkDict[folderID] };

		 

		// Check if already exists
		if (fullBookmarkDict[folderID].bookmarks[drillID] != null) {
			 
			delete nextFolderBookmarkDictionary.bookmarks[drillID];
		}
		if (authenticationStatus !== AuthenticationStatus.Authenticated) {
			 
//			this.setState({ bookmarkDictionary: nextBookmarkDictionary });
			return;
		}

		 
		 
//		patchFolderBookmarks({bookmarks: Object.values(nextBookmarkFolderDictionary)})
		this.patchFolderBookmarkData(folderID, { bookmarks: Object.values(nextFolderBookmarkDictionary.bookmarks) });
		this.fetchUserData();
	}

	openFilterModal() {
		this.setState({ isFilterModalOpen: true });
	}

	async toggleFilters() {
		if (!this._ismounted)
			return;

		await this.setState(({ filtersEnabled }) => ({
			filtersEnabled: !filtersEnabled,
		}));
	}

	clearFilters() {
		this.updateFilters(null);
	}

	clearSearchText() {
		this.setState({searchText: ''});
	}

	// async fetchFilteredHouses(filters) {
	// 	//return  // HL 4 2022  volgens mij kan dit er uit
	// 	if (!this._ismounted)
	// 		return;

	// 	await this.setState({ isLoadingFilteredList: true });

	// 	const queryParameters = [];

	// 	// TODO - Eric, this needs to be looked at
	// 	if (filters.niveau != null && filters.niveau.length > 0) {
	// 		queryParameters.push({
	// 			queryParameter: 'niveau',
	// 			value: filters.niveau.length > 1 ? `${RangeOperator.within}(${filters.niveau.join(',')})` : filters.niveau[0],
	// 		});
	// 	}

	// 	if (filters.hoofdtechniek != null && filters.hoofdtechniek.length > 0) {
	// 		queryParameters.push({
	// 			queryParameter: 'hoofdtechniek',
	// 			value: filters.hoofdtechniek.length > 1 ? `${RangeOperator.within}(${filters.hoofdtechniek.join(',')})` : filters.hoofdtechniek[0],
	// 		});
	// 	}

	// 	if (filters.subtechniek != null && filters.subtechniek.length > 0) {
	// 		queryParameters.push({
	// 			queryParameter: 'subtechniek',
	// 			value: filters.subtechniek.length > 1 ? `${RangeOperator.within}(${filters.subtechniek.join(',')})` : filters.subtechniek[0],
	// 		});
	// 	}

	// 	try {
	// 		const response = await API.getDrills(queryParameters);
	// 		if ( this.checkErrors(response) ) { return };

	// 		if (!this._ismounted)
	// 			return;

	// 		const filteredHouseList = response.data.reduce((result, currentItem) => {
	// 			result.push(this.formatListResponseDataItem(currentItem));
	// 			return result;
	// 		}, []);

			 

	// 		await this.setState({ filteredHouseList });
	// 	} catch (e) {
	// 		console.exception(e);

	// 		if (!this._ismounted)
	// 			return;

	// 		// TODO Add filterHouseListError ?
	// 		this.setState({ filteredHouseList: [] });
	// 	}
	// }

	async addLogging(userData) {
		// Create logging object
		// console.log('addLogging: ');
		// console.log(userData);
		// Send log to server
		const data = {	
			useragent: navigator.userAgent?navigator.userAgent:'',
			appname: navigator.appName?navigator.appName:'',
			appversion: navigator.appVersion?navigator.appVersion:'',
			product: navigator.product?navigator.product:'',
			platform: navigator.platform?navigator.platform:'',
			language: navigator.language?navigator.language:'',
			screen_width: screen.width?screen.width:'',
			screen_height: screen.height?screen.height:'',
			log: userData.id,
			userid: userData.id,
			timestamp_utc: new Date().toISOString(),
		};

		const postResponse = await Logging_API.postLogEntry(
				userData.id, 
				{...data}
			);
		if ( this.checkErrors(postResponse) ) { return };


		if (!this._ismounted || postResponse.status !== 201 || postResponse.data == null || isStringNullOrEmpty(postResponse.data.href)) {
			console.warn('Could not update the state with the newly created filter preferences.');
			return;
		}
	}

	/** Add new private bookmark folder for user
	 * 1) Refresh bookmarks as one could have been added through another device
	 * 2) Check if already exists
	 * 3) Get id of private type
	 * 4) Add new folder of type private
	 * 6) Refresh user data including bookmark folders
	 */
	async addUserBookmarkFolder(folderName) {
		const {
			fullBookmarkDict,
		} = this.state;
		// 1) First refresh all bookmarks so we can check if it already exists
		await this.fetchUserData();
		 
		 
		// 2) Loop over bookmarks and check if folderName already exists
		for (const [key, bookmark] of Object.entries(fullBookmarkDict)) {
			if (bookmark.label == folderName) {
				// TODO: Should we inform the user that it already exists or will
				//       he see it in the list and think it just got created
				 
				return;
			}
		}

		const {
			state: {
				supportedBookmarkFolderTypes,
			 }
		} = this;
		 

		 
		if (!this._ismounted)
			return;

		if (folderName == null || folderName === '') {
			return;
		}

		if (!this._ismounted || this.state.authenticationStatus !== AuthenticationStatus.Authenticated)
			return;

		const { userData } = this.state;

		// 3) Get id for bookmarkType 'private'
		let privateId = null;
		for (const [key, bookmarkType] of Object.entries(supportedBookmarkFolderTypes)) {
			if (bookmarkType.label == 'private') {
				privateId = key;
			}
		}
		if (privateId == null) {
			 
			return;
		}
		 
		// 4) Create bookmark folder
		const data = {Bookmarks: [], sub_folder: [], bookmark_type: privateId};
		const postResponse = await API.postBookmarkFolder(
			userData.id, 
			{...data, bookmark_folders: folderName});

		if ( this.checkErrors(postResponse) ) { return };

		if (!this._ismounted || postResponse.status !== 201 || postResponse.data == null || isStringNullOrEmpty(postResponse.data.href)) {
			console.warn('Could not update the state with the newly created filter preferences.');
			return;
		}

		// TODO: 5) Add folder as subfolder of home

		// Refresh user data including new bookmark folder
		this.fetchUserData();

	}

	// Updates the state with new filters and updates the user's filter preferences if necessary.
	async updateFilters(filters, updatePreference = false) {

		 

		 
		if (!this._ismounted)
			return;

		const {
			houseList,
		} = this.state;

		if (filters == null || filters === {}) {
			await this.setState({
				filteredHouseList: [],
				filters: null,
				filtersEnabled: true,
				isLoadingFilteredList: false,
			});

			return;
		}

		// NOTE
		// To make the filtering happen on the server side instead of the front-end,
		// uncomment the code between "FILTER FROM THE API" and "END", and comment
		// what is between "FILTER LOCALLY" and "END", and vice versa.

		// --- FILTER FROM THE API
		// this.fetchFilteredHouses(filters);
		//
		// await	this.setState({
		// 	filters,
		// 	filtersEnabled: true,
		// 	isLoadingFilteredList: false,
		// });
		// --- END

		// --- FILTER LOCALLY
		const filteredLevelSet = new Set(filters.niveau);
		const filteredTechnicSet = new Set(filters.hoofdtechniek);
		const filteredSubTechnicSet = new Set(filters.subtechniek);
		const filteredHouseList = houseList.filter((item) => {
			if (filteredLevelSet.size > 0 && (item.levelSet.size <= 0 || !setIncludesAny(filteredLevelSet, item.levelSet)))
				return false;

			if (filteredTechnicSet.size > 0 && filteredSubTechnicSet.size > 0) {
				if ((item.technicSet.size <= 0 || !setIncludesAny(filteredTechnicSet, item.technicSet)) && (item.subTechnicSet.size <= 0 || !setIncludesAny(filteredSubTechnicSet, item.subTechnicSet)))
					return false;

				return true;
			}

			if (filteredTechnicSet.size > 0 && (item.technicSet.size <= 0 || !setIncludesAny(filteredTechnicSet, item.technicSet)))
				return false;

			if (filteredSubTechnicSet.size > 0 && (item.subTechnicSet.size <= 0 || !setIncludesAny(filteredSubTechnicSet, item.subTechnicSet)))
				return false;

			return true;
		});

		 

		await	this.setState({
			filters,
			filteredHouseList,
			filtersEnabled: true,
			isLoadingFilteredList: false,
		});
		// --- END

		if (!this._ismounted || !updatePreference || filters == null || this.state.authenticationStatus !== AuthenticationStatus.Authenticated)
			return;

		const { userData, filterPreference } = this.state;

		if (filterPreference == null) {
			const postResponse = await API.postFilterPreference(userData.id, {
				...filters,
				zoekopdrachten: `filter preference of user ${userData.id}`,
			});
			if ( this.checkErrors(postResponse) ) { return };

			 

			if (!this._ismounted || postResponse.status !== 201 || postResponse.data == null || isStringNullOrEmpty(postResponse.data.href)) {
				console.warn('Could not update the state with the newly created filter preferences.');
				return;
			}

			const id = getIdFromApiUrl(postResponse.data.href);

			await this.setState({
				filterPreference: {
					...filters,
					id,
				},
			});
			return;
		}

		const patchResponse = await API.patchFilterPreference(filterPreference.id, filters);
		if ( this.checkErrors(patchResponse) ) { return };
		 

		if (!this._ismounted || patchResponse.status !== 200) {
			console.warn('Could not update the state with the updated filter preferences.');
			return;
		}

		await this.setState(previousState => ({
			filterPreference: {
				...previousState,
				...filters,
			}
		}));
	}

	// switchLanguage(languageToApply) {
	async switchLanguage(languageToApply) {
		// console.log('switchLanguage');
		// console.log(languageToApply);

		if (!this._ismounted || i18n.language === languageToApply.label)
			return;

		// console.log('switchLanguage   go on...');

		i18n.changeLanguage(languageToApply.label);  //Hl 4 2022 try doing this first
		localStorage.setItem(StorageKey.PreferredLanguage, languageToApply.label);  //


		await Promise.all([
			this.fetchLearningCurves(), //HL 4 2022    load data again, because there the i18n_label fields etc. are set
			this.fetchSupportedTechnics(), // new also
		]);
	 	
		const { authenticationStatus } = this.state;

		if (authenticationStatus === AuthenticationStatus.Authenticated) {

		 	this.patchUserData({ taal: languageToApply.id });
			
			// console.log('end of switchLanguage, authenticated user');
			return;
		}

		i18n.changeLanguage(languageToApply.label);
		localStorage.setItem(StorageKey.PreferredLanguage, languageToApply.label);



		// console.log('end of switchLanguage');
	}

	switchTheme(themeToApply) {
		if (!this._ismounted || this.state.theme === themeToApply.label)
			return;

		const { authenticationStatus } = this.state;

		if (authenticationStatus === AuthenticationStatus.Authenticated) {
			this.patchUserData({ nightmode: themeToApply.id });
			return;
		}

		this.setState({ theme: themeToApply.label });
		localStorage.setItem(StorageKey.Theme, themeToApply.label);
	}

	switchEmailNotifications(booleanID) {
		const { authenticationStatus } = this.state;

		if (authenticationStatus !== AuthenticationStatus.Authenticated)
			return;

		if (this.state.userData.emailNotifications === booleanID)
			return;

		this.patchUserData({ emailmeldingen: booleanID });
	}

	switchPushNotifications(booleanID) {
		const { authenticationStatus } = this.state;

		if (authenticationStatus !== AuthenticationStatus.Authenticated)
			return;

		if (this.state.userData.pushNotifications === booleanID)
			return;

		this.patchUserData({ pushmeldingen: booleanID });
	}

	applyUserPreferences(userData) {
		 
		 
		if (!this._ismounted) { 
			return;
		}

		if (i18n.language !== userData.language) {
			i18n.changeLanguage(userData.language);
			localStorage.setItem(StorageKey.PreferredLanguage, userData.language);
		}

		this.setState((currentState) => {
			 
			const changes = {
				bookmarkDictionary: { ...userData.bookmarkDictionary }
			};

			if (currentState.theme !== userData.theme) {
				changes.theme = userData.theme;
				localStorage.setItem(StorageKey.Theme, userData.theme);
			}

			return changes;
		});
	}

	// Aggregates the current preferences set in the app to include
	// them in the user data if the user is a visitor and registers
	// after having saved bookmarks, changed language, etc.
	getPreferencesForNewUser() {
		const {
			theme,
			booleanSelectors,
			supportedLanguages,
			bookmarkDictionary,
		} = this.state;

		const language = supportedLanguages.find(({ label }) => label === i18n.language);

		return {
			pushmeldingen: booleanSelectors.nee.id,
			emailmeldingen: booleanSelectors.nee.id,
			bookmarks: Object.keys(bookmarkDictionary),
			taal: language != null ? language.id : supportedLanguages[0].id,
			nightmode: theme === ThemeKey.Dark ? booleanSelectors.ja.id : booleanSelectors.nee.id,
		};
	}


	// --- Event methods
	onLocationChanged(location, action) {
		if (!this._ismounted || location == null || isStringNullOrEmpty(location.pathname))
			return;

		const path = location.pathname.substring(1).split('/', 1);
		if (path.length < 1 || isStringNullOrEmpty(path[0]))
			return;

		const nextPath = path[0];

		const { currentPath } = this.state;

		if (currentPath === nextPath)
			return;

		this.setState({ currentPath: nextPath }, () => window.scrollTo(0, 0));
	}

	async onSignedIn(credentials, rawUserData) {
		// console.log('onSignedIn1');
		if (!this._ismounted)
			return;
		
			// console.log('onSignedIn2');

		localStorage.setItem(StorageKey.UserCredentials, JSON.stringify(credentials));

		// Change username and password for API calls
		API.setCommonConfiguration();

		const userData = formatUserData(rawUserData);

		await this.setState({
			userData,
			authenticationStatus: AuthenticationStatus.Authenticated,
		});

		if (!this._ismounted)
			return;

		this.applyUserPreferences(userData);
// this.addLogging(userData);
		this.fetchDrills();
		this.fetchUserData();
		this.setfirstLogin();
		// this.fetchUserFilterPreference();
	}

	async onAccountEdited(rawUserData) {
		if (!this._ismounted)
			return;

		const userData = formatUserData(rawUserData);

		this.onCredentialsChanged(userData.users, null);

		await this.setState({ userData });
	}

	onCredentialsChanged(username, password) {
		if (!this._ismounted)
			return;

		if (this.state.authenticationStatus !== AuthenticationStatus.Authenticated) {
			console.error('Session credentials cannot be set or edited when not signed in.');
			return;
		}

		if (!isStringNullOrEmpty(username) && !isStringNullOrEmpty(password)) {
			localStorage.setItem(StorageKey.UserCredentials, JSON.stringify({
				username,
				password
			}));
			return;
		}

		const json = JsonExtension.parseOrNull(localStorage.getItem(StorageKey.UserCredentials));

		if (json == null) {
			console.error('Session credentials could not be parsed.');
			return;
		}

		const { username: storedUsername, password: storedPassword } = json;

		if (!isStringNullOrEmpty(password)) {
			localStorage.setItem(StorageKey.UserCredentials, JSON.stringify({
				username: storedUsername,
				password
			}));

			// Update API credentials
			API.setCommonConfiguration();

			return;
		}

		localStorage.setItem(StorageKey.UserCredentials, JSON.stringify({
			username,
			password: storedPassword
		}));

		// Update API credentials
		API.setCommonConfiguration();
	}

	onSignedOut() {
		if (!this._ismounted)
			return;

		localStorage.removeItem(StorageKey.UserCredentials);

		this.setState({
			userData: null,
			filteredHouseList: [],
			filters: null,
			filterPreference: null,
			bookmarkDictionary: {},
			filtersEnabled: true,
			isLoadingFilteredList: false,
			authenticationStatus: AuthenticationStatus.Visitor,
		}, () => this.props.history.push('/drills'));
	}
}

App.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
		listen: PropTypes.func.isRequired,
	}),
	location: PropTypes.object,
};
export default 
	withTranslation()(withRouter(App));
